<template>
  <div>
    <cp-general-modal
      ref="modalLogic"
      :title="title"
      :title-icon="titleIcon"
      :ok-text="title"
      :default-value="formGroup"
      size="lg"
      @onInput="updateFormGroup"
      @onOk="handleSubmit"
    >
      <div class="row">
        <div class="col-md-6">
          <table class="table table-striped table-borderless mb-0">
            <tbody>
              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.roundName') }}*
                </td>
                <td class="align-middle">
                  <cp-input
                    name="name"
                    validate="required"
                    :disabled="disabled || isEvergreenRound"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.activeStatus') }}
                </td>
                <td class="align-middle">
                  <cp-switch
                    name="isActive"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.visibleToInvestor') }}
                </td>
                <td class="align-middle">
                  <cp-switch
                    name="isTokenCalculatorVisible"
                    :disabled="disabled"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped table-borderless mb-0">
            <tbody>
              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.minimumFiatInvestments', [issuerMainCurrencyIdentifier]) }}*
                </td>
                <td class="align-middle">
                  <cp-input
                    v-model="formGroup.minInvestmentFiat"
                    name="minInvestmentFiat"
                    :validate="`required|min_value:0|excluded:0`"
                    input-type="number"
                    :disabled="disabled"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.minimumCryptoInvestments', [issuerMainCurrencyIdentifier]) }}*
                </td>
                <td class="align-middle">
                  <cp-input
                    v-model="formGroup.minInvestmentCrypto"
                    name="minInvestmentCrypto"
                    :validate="`required|min_value:0|excluded:0`"
                    input-type="number"
                    :disabled="disabled"
                  />
                </td>
              </tr>

              <tr v-if="isEvergreenRound">
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.minSubsequentInvestmentFiat', [issuerMainCurrencyIdentifier]) }}
                </td>
                <td class="align-middle">
                  <cp-input
                    v-model="formGroup.minSubsequentInvestmentFiat"
                    name="minSubsequentInvestmentFiat"
                    :validate="`min_value:0|excluded:0`"
                    input-type="number"
                    :disabled="disabled"
                  />
                </td>
              </tr>
              <tr v-if="isEvergreenRound">
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.minSubsequentInvestmentCrypto', [issuerMainCurrencyIdentifier]) }}
                </td>
                <td class="align-middle">
                  <cp-input
                    v-model="formGroup.minSubsequentInvestmentCrypto"
                    name="minSubsequentInvestmentCrypto"
                    :validate="`min_value:0|excluded:0`"
                    input-type="number"
                    :disabled="disabled"
                  />
                </td>
              </tr>
              <tr v-if="tokenGroupMinInvestmentShared">
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.applyMinInvestToTheTokenGroup') }}
                </td>
                <td
                  class="align-middle"
                >
                  <cp-switch
                    v-model="formGroup.applyMinInvestToTheTokenGroup"
                    name="applyMinInvestToTheTokenGroup"
                  />
                </td>
              </tr>


              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.docuSignId') }}
                </td>
                <td class="align-middle">
                  <cp-input
                    name="subscriptionAgreementDocusignId"
                    :disabled="disabled"
                  />
                </td>
              </tr>
              <tr v-if="canEditCustodianDocuSignId">
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.custodianDocuSignId') }}
                </td>
                <td class="align-middle">
                  <cp-input
                    name="subscriptionAgreementCustodianDocusignId"
                    :disabled="disabled"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped table-borderless mb-0">
            <tbody>
              <tr>
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.startDate') }}*
                </td>
                <td class="align-middle">
                  <cp-time-date-picker
                    v-model="formGroup.startsAt"
                    name="startsAt"
                    validate="required"
                    columns-layout
                    read-only
                    :disabled="disabled"
                  />
                </td>
              </tr>
              <tr v-if="formGroup.startsAt">
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.closeDate') }}{{ !isEvergreenRound?'*':null }}
                </td>
                <td class="align-middle">
                  <cp-time-date-picker
                    v-model="formGroup.endsAt"
                    name="endsAt"
                    :validate="isEvergreenRound? '':'required'"
                    :open-date="new Date(formGroup.endsAt || formGroup.startsAt)"
                    :disabled-dates="disableDates"
                    columns-layout
                    read-only
                    :disabled="disabled"
                  />
                </td>
              </tr>
              <tr v-if="formGroup.endsAt">
                <td class="align-middle">
                  {{ $t('configurationFundraise.roundModal.input.issuanceDate') }}{{ !isEvergreenRound?'*':null }}
                </td>
                <td class="align-middle">
                  <cp-time-date-picker
                    v-model="formGroup.issuanceDate"
                    name="issuanceDate"
                    :validate="isEvergreenRound? '':'required'"
                    :open-date="new Date(formGroup.issuanceDate || formGroup.endsAt)"
                    :disabled-dates="{ to: new Date(formGroup.endsAt) }"
                    columns-layout
                    read-only
                    :disabled="disabled"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--      START  -->
      <div class="card bg-light mt-4">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row align-items-center">
                <div class="col-3">
                  {{ $t('configurationFundraise.roundModal.input.tokenPrice') }}*
                </div>
                <div class="col-9">
                  <div class="input-group mt-0">
                    <cp-input
                      v-model.number="tokenValue"
                      class="align-token-value"
                      input-type="number"
                      name="Token Value"
                      :validate="`required|min_value:0|excluded:0`"
                      :error="biggerThenEqualTo(tokenValue, $t('configurationFundraise.roundModal.label.tokenPrice'))"
                      :disabled="disabled"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">{{ issuerMainCurrencyIdentifier }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <h6 class="mt-3">
            {{ $t('configurationFundraise.roundModal.label.bonusForLargeInvestments') }}
          </h6>
          <div
            v-for="(tier, index) in bonusTiers"
            :key="tier.id"
            :item="tier"
            :index="index"
            class="row align-items-start"
          >
            <div class="col-md-5">
              <div>
                <label class="form-label">
                  {{ $t('configurationFundraise.roundModal.input.minimumInvestmentForBonus') }}
                </label>
                <div class="input-group mt-0">
                  <cp-input
                    v-model.number="tier.minInvestmentAmount"
                    class="overwrite columns-view"
                    input-type="number"
                    :name="`Min investment ${index}`"
                    :validate="'min_value:'+minAllowedAmount(index)"
                    :error="errorMinAmount(tier.minInvestmentAmount, index, $t('configurationFundraise.roundModal.label.bonusAmount'))"
                    :disabled="disabled"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{ issuerMainCurrencyIdentifier }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div>
                <label class="form-label">
                  {{ $t('configurationFundraise.roundModal.input.bonus') }}
                </label>
                <div class="input-group mt-0">
                  <cp-input
                    v-model.number="tier.bonusPercentage"
                    class="columns-view"
                    input-type="number"
                    :name="`Bonus Percentage ${index}`"
                    :validate="tier.minInvestmentAmount
                      ? `required|between:${setBiggerThen},100` : ''"
                    :error="numericRange(tier.bonusPercentage, $t('configurationFundraise.roundModal.label.bonusPercentage'), tier.minInvestmentAmount)"
                    :disabled="disabled"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 d-flex align-self justify-content-end">
              <div class="form-group">
                <button
                  v-if="index > 0"
                  type="button"
                  class="btn btn-sm btn-icon btn-danger"
                  :disabled="disabled"
                  @click="removeTier($event ,index)"
                >
                  <span class="ion ion-ios-trash" />
                </button>
                <button
                  v-if="bonusTiers[index].minInvestmentAmount"
                  type="button"
                  class="btn btn-sm btn-icon btn-primary ml-2"
                  :disabled="disabled"
                  @click="addTier($event ,index)"
                >
                  <span class="ion ion-ios-add" />
                </button>
              </div>
            </div>
          </div>
          <hr class="mt-0">
        </div>
      </div>
      <!--     END  -->
      <div class="row mt-4">
        <div class="col-12">
          <div class="card bg-light">
            <div class="card-header">
              <h6 class="mb-0">
                {{ $t('configurationFundraise.roundModal.input.investmentTermsAndConditions') }}*
              </h6>
            </div>
            <div class="card-body">
              <cp-html-text-area
                v-model="formGroup.investmentTermsAndConditions"
                name="investmentTermsAndConditions"
                validate="required"
                :disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>
      <jurisdictional-exceptions
        :data="jurisdictionalExceptionsData"
        :disabled="disabled"
        @change="updateJurisdictionalExceptionsData"
      />
      <div class="row mt-4">
        <div class="col-12">
          <div class="card bg-light">
            <div class="card-header">
              <h6 class="mb-0">
                {{ $t('configurationFundraise.roundModal.input.json') }}
              </h6>
            </div>
            <div class="card-body">
              <cp-textarea
                v-model="formGroup.json"
                v-validate.initial="'json'"
                name="json"
                :disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="mt-0">
      <div class="row mt-4">
        <div class="col-12">
          * {{ $t('configurationFundraise.roundModal.label.mandatory') }}
        </div>
      </div>
    </cp-general-modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpTimeConverter from '~/mixins/time-converter';
import JurisdictionalExceptions from '~/pages/_idIssuer/configuration/fundraise/components/jurisdictional-exeptions';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';
import {
  CpInput,
  CpSwitch,
  CpTimeDatePicker,
  CpHtmlTextArea,
} from '~/components/common/standalone-components/inputs';

export default {
  name: 'CpRoundModal',
  components: {
    CpHtmlTextArea,
    CpTimeDatePicker,
    CpSwitch,
    CpGeneralModal,
    CpInput,
    JurisdictionalExceptions,
    CpTextarea,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    tokenGroupMinInvestmentShared: {
      type: Number,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    isEvergreenRound: {
      type: Boolean,
      default: false,
    },
    roundInfo: {
      type: Object,
      default: () => {},
    },
    issuerConfVar: {
      type: Object,
      default: () => {},
    },
    allRounds: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formGroup: {},
      bonusTiers: [{ minInvestmentAmount: '', bonusPercentage: null }],
      tokenValue: '',
      setBiggerThen: 0.0000000000001,
      isTokenCalculatorVisible: true,
      isJurisdictionalExceptionError: false,
      jurisdictionalExceptionsData: [],
      issuerConf: {},
    };
  },
  computed: {
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    disableDates() {
      const { startsAt } = this.formGroup;
      if (startsAt) {
        const startDate = new Date(startsAt);
        return { to: new Date(startDate.setDate(startDate.getDate() + 1)) };
      }
      return {};
    },
    canEditCustodianDocuSignId() {
      return ['superadmin', 'megaadmin'].includes(this.authorizationLevel);
    },
  },
  watch: {
    deep: true,
    async roundInfo(info) {
      this.jurisdictionalExceptionsData = info.jurisdictionalExceptionsData && info.jurisdictionalExceptionsData.length ? [...info.jurisdictionalExceptionsData] : [];
      this.formGroup = { ...info };

      if (Object.keys(info).length === 0) {
        this.formGroup.minInvestmentFiat = this.issuerConfVar.issuerMinInvestmentFiat;
        this.formGroup.minInvestmentCrypto = this.issuerConfVar.issuerMinInvestmentCrypto;
        this.formGroup.minSubsequentInvestmentFiat = this.issuerConfVar.issuerMinSubsequentInvestmentFiat;
        this.formGroup.minSubsequentInvestmentCrypto = this.issuerConfVar.issuerMinSubsequentInvestmentCrypto;
      }

      this.formGroup.isActive = info.status === 'active';
      if (this.formGroup.tokenCalculatorData) {
        this.tokenValue = this.formGroup.tokenCalculatorData.tokenValue;
        if (this.formGroup.tokenCalculatorData.tiers.length) this.bonusTiers = this.formGroup.tokenCalculatorData.tiers;
      }
    },
    tokenValue() {
      if (this.formGroup.tokenCalculatorData) {
        this.formGroup.tokenCalculatorData.tokenValue = this.tokenValue;
      }
    },
    editMode(edit) {
      if (!edit) this.clearForm();
    },
  },
  methods: {
    validateDates() {
      if (this.formGroup.startsAt
        && this.formGroup.endsAt
        && moment(this.formGroup.endsAt).diff(this.formGroup.startsAt, 'days') < 1
      ) {
        this.formGroup.endsAt = moment(this.formGroup.startsAt).add(1, 'days').format('YYYY-MM-DD HH:mm');
      }

      if (this.formGroup.endsAt
        && this.formGroup.issuanceDate
        && moment(this.formGroup.issuanceDate).isBefore(this.formGroup.endsAt)
      ) {
        this.formGroup.issuanceDate = this.formGroup.endsAt;
      }
    },
    show() {
      Object.assign(this.$data, this.$options.data());
      this.$refs.modalLogic.show();
    },
    hide() {
      this.$refs.modalLogic.hide();
    },
    handleSubmit(data) {
      if (!this.isJurisdictionalExceptionError) {
        this.$emit('onSubmit', { editMode: this.editMode, body: this.getBody({ ...data, jurisdictionalExceptionsData: this.jurisdictionalExceptionsData }) });
      }
    },
    getBody(data) {
      const {
        name,
        endsAt,
        isActive,
        startsAt,
        id: roundId,
        issuanceDate,
        minInvestmentFiat,
        minInvestmentCrypto,
        minSubsequentInvestmentCrypto,
        minSubsequentInvestmentFiat,
        tokenCalculatorData,
        isTokenCalculatorVisible,
        investmentTermsAndConditions,
        jurisdictionalExceptionsData,
        subscriptionAgreementDocusignId,
        subscriptionAgreementCustodianDocusignId,
        json,
        applyMinInvestToTheTokenGroup,
      } = data;

      const mappedDate = (item) => {
        if (this.isEvergreenRound && (item === '' || item === null)) return null;
        return item ? moment(item).toISOString() : '';
      };


      const result = {
        name,
        roundId,
        minInvestmentFiat: Number(minInvestmentFiat),
        minInvestmentCrypto: Number(minInvestmentCrypto),
        minSubsequentInvestmentCrypto,
        minSubsequentInvestmentFiat,
        isTokenCalculatorVisible,
        jurisdictionalExceptionsData,
        investmentTermsAndConditions,
        subscriptionAgreementDocusignId,
        subscriptionAgreementCustodianDocusignId,
        status: this.getRoundStatus(isActive),
        startsAt: startsAt ? moment(startsAt).toISOString() : '',
        endsAt: mappedDate(endsAt),
        issuanceDate: mappedDate(issuanceDate),
        tokenCalculatorData: tokenCalculatorData || { tokenValue: this.tokenValue },
        json: json !== '' ? json : null,
        applyMinInvestToTheTokenGroup: applyMinInvestToTheTokenGroup || false,
      };

      const tiers = this.bonusTiers.filter(tier => (tier.minInvestmentAmount && tier.minInvestmentAmount > 0)).map(({ bonusPercentage, minInvestmentAmount }) => ({ bonusPercentage, minInvestmentAmount }));
      result.tokenCalculatorData.tiers = tiers;

      return result;
    },
    clearForm() {
      this.formGroup = {};
      this.formGroup.isActive = false;
      this.bonusTiers = [{ minInvestmentAmount: '', bonusPercentage: null }];
      this.tokenValue = '';
      this.jurisdictionalExceptionsData = [];
      this.isJurisdictionalExceptionError = false;
    },
    removeTier(event, index) {
      this.bonusTiers.splice(index, 1);
    },
    addTier(event, index) {
      const hasEmpty = this.bonusTiers.some(item => Boolean(item.minInvestmentAmount) === false);
      if (!hasEmpty) this.bonusTiers.splice(index + 1, 0, { minInvestmentAmount: '', bonusPercentage: null });
    },
    minAllowedAmount(index) {
      const {
        minInvestmentCrypto: minCrypto,
        minInvestmentFiat: minFiat,
      } = this.formGroup;
      const minInvestment = Math.max(minCrypto, minFiat);
      if ((index - 1) >= 0) {
        return this.bonusTiers[(index - 1)].minInvestmentAmount + this.setBiggerThen;
      }
      return minInvestment || 0;
    },
    biggerThenEqualTo(value, fieldName = 'value') {
      if (value <= 0 && typeof value === 'number') {
        return this.$t('configurationFundraise.roundModal.message.numericZeroError', [fieldName]);
      }
      if (typeof value === 'string' && value) {
        return this.$t('configurationFundraise.roundModal.message.numericTypeError', [fieldName]);
      }
    },
    numericRange(value, fieldName = 'value', investBonus) {
      if (investBonus && (value <= 0 || value > 100) && typeof value === 'number') {
        return this.$t('configurationFundraise.roundModal.message.numericRangeError', [fieldName]);
      }
      if (typeof value === 'string' && value) {
        return this.$t('configurationFundraise.roundModal.message.numericTypeError', [fieldName]);
      }
      return '';
    },
    errorMinAmount(value, index, fieldName = 'amount') {
      const minAllowedAmount = this.minAllowedAmount(index);
      if (!value || value >= minAllowedAmount || value <= 0) {
        return '';
      }
      return this.$t('configurationFundraise.roundModal.message.minAmountError', [fieldName, minAllowedAmount]);
    },
    updateFormGroup(item) {
      this.formGroup = { ...item };
      this.validateDates();
    },
    updateJurisdictionalExceptionsData({ data, error }) {
      if (!error) this.jurisdictionalExceptionsData = data;
      this.isJurisdictionalExceptionError = error;
    },
    getRoundStatus(isActive) {
      if (isActive) return 'active';
      if (this.roundInfo.status === 'done') return 'done';
      return 'planned';
    },
  },
};
</script>
<style scoped lang="scss">
  .input-group-text {
    height: calc(1.54em + 0.876rem + 2px);
  }

  .columns-view {
    display: inline-block;
    max-width: 79%;
  }

  .align-self {
    align-self: flex-end;
  }

  .align-token-value {
    width: 54%;
  }
</style>
